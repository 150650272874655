var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contactRM" }, [
    _c("section", [
      _c("div", { staticClass: "contactLeft" }, [
        _c("h2", [_vm._v("Contact Us")]),
        _c("div", [
          _c("ul", [
            _c("li", [
              _vm._m(0),
              _vm._v(" " + _vm._s(_vm.$t("companyAddress")) + " ")
            ]),
            _vm._m(1),
            _vm._m(2),
            _c("li", [_vm._m(3), _vm._v(" " + _vm._s(_vm.$t("parking")) + " ")])
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$t("partner_comment")))]),
          _c("a", { attrs: { href: "mailto:info@reviewmind.com" } }, [
            _c("button", [_vm._v(_vm._s(_vm.$t("contactUs")))])
          ])
        ])
      ]),
      _c("div", { attrs: { id: "map" } })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fas fa-map-marker-alt" }),
      _vm._v(" Address ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _c("i", { staticClass: "fas fa-phone-alt" }),
        _vm._v(" Phone ")
      ]),
      _vm._v(" 02-713-5847 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _c("i", { staticClass: "fas fa-envelope-open-text" }),
        _vm._v(" E-mail ")
      ]),
      _vm._v(" info@reviewmind.com ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fas fa-parking" }),
      _vm._v(" Parking ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }